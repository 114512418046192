/** @jsx jsx */
import Seo from "../components/seo"
import { jsx } from "theme-ui"
// import { Breadcrumb } from "../components/breadcrumb"
import Layout from "../components/layout"
import { graphql } from "gatsby"

export const pageQuery = graphql`
query PrivacyQuery($id: String!) {
  markdownRemark(id: { eq: $id }) {
    id
    html
    props: frontmatter {
      title
    }
  }
}`

const PrivacyPage = ({ data }) => {
  const { markdownRemark: { html, props: { title } } } = data

  return <Layout className="faq-page">
    <Seo/>
    {/*<Breadcrumb title="Privacy" section="Legal"/>*/}
    <section className="privacy">
      <h1>{title}</h1>
      <div className="privacy-content" dangerouslySetInnerHTML={{ __html: html }}/>
    </section>
  </Layout>
}

export default PrivacyPage
